.confirmDeletePopupOuter
    display: flex
    flex-direction: column
    justify-content: center
    width: 100% !important
    .popupContainer
        display: flex
        flex-direction: column
        justify-content: center
        text-align: center
        padding: $size-m2
    .inputHeader
        @extend .h2
        padding-bottom: $size-m
    .inputContainer
        display: flex
        justify-content: center
        .inputContainer
            width: 75%
        .textInput
            padding-bottom: $size-m
    .buttonContainer
        display: flex
        justify-content: center
        gap: $size-m
    .confirmButton
        width: 50%
