.queueOuter
    .queueTitle
        @extend .h2
        padding-top: $size-m
        padding-bottom: $size-m

    .queueInner
        background: rgba(0, 0, 0, 0.2)
        border-radius: $size-m
        min-height: 50px
        overflow: hidden
        .generating
            opacity: 0.8
        .queueItem
            display: grid
            grid-template-columns: 10% 15% 55% 20%
            align-items: center
            justify-content: space-between
            padding: $size-m
            flex: 1
            &.owner
                grid-template-columns: 10% 15% 45% 20% 10%
            &.isSelected
                background: rgba(0, 0, 0, .3)
                overflow: hidden

            &:not(:last-child)
                border-bottom: 1px solid #FFFFFF
            .graphicInfo
                display: flex
                align-items: center
                justify-content: center
                .image
                    display: flex
                    width: 100%
                    line-height: 100%
                    text-align: center
            .queueItemIdx
                display: flex
                align-items: center
                color: #FFFFFF
                padding-right: $size-s
                text-align: center
            .queueItemTitle
                @extend .h4
                display: flex
                align-items: center
                color: #FFFFFF
                padding-left: $size-s
                height: 100%
            .createdInfo
                display: flex
                flex-direction: column
                justify-content: center
                .authorName
                    @extend .h4
                    text-align: center
                .createdAt
                    @extend .h4
                    text-align: center
                    font-weight: 300
            .dragItemContainer
                display: flex
                justify-content: flex-end
                align-items: center
                flex-grow: 0
                flex-shrink: 0
