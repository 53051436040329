.playlistOuter
    background: linear-gradient(180deg, #222222 4.04%, #34654E 47.44%, #1D1D1D 94.36%)
    padding-left: $size-m
    padding-right: $size-m
    color: white !important
    .playlistInner
        min-height: 100vh
        overflow: hidden
        .displayURLOuter
            padding-bottom: $size-m2
            .displayURLHeader
                @extend .h2
                padding-bottom: $size-s
            .displayURL
                @extend .h3
                color: var(--primary)

        .imageContainer
            width: 100%
            aspect-ratio: 1 / 1
            .image
                width: 100%
                height: 100%
                object-fit: cover
                object-position: center
                margin-bottom: $size-m
        .welcomeText
            display: flex
            align-items: flex-end
            font-family: 'Montserrat'
            font-style: normal
            font-weight: 700
            font-size: 48px
            line-height: 121.81%
            padding-top: $size-xl
            padding-bottom: $size-l
        .currentArtwork
            display: flex
            align-items: center
            padding-bottom: $size-m
            font-family: 'Montserrat'
            font-style: normal
            font-weight: 700
            font-size: 22px
            line-height: 121.81%
        .artworkTitle
            display: flex
            align-items: center
            font-family: 'Montserrat'
            font-style: normal
            font-weight: 700
            font-size: 22px
            line-height: 121.81%
        .authorInfo
            display: flex
            justify-content: space-between
            align-items: center
            margin-top: $size-s2
            .timeAgo
                font-size: 11px
            .authorText
                display: flex
                align-items: center
                font-family: 'Montserrat'
                font-style: normal
                font-weight: 500
                font-size: 12px
                line-height: 121.81%
                .author
                    margin-left: $size-xs2
        .requestArtwork
            display: flex
            align-items: center
            margin-top: $size-l
            font-family: 'Montserrat'
            font-style: normal
            font-weight: 700
            font-size: 22px
            line-height: 121.81%
            padding-bottom: $size-m
        .loadingContainer
            display: flex
            justify-content: center
            align-items: center
            padding-top: $size-m
            padding-bottom: $size-m
        .loadingContainer
            display: flex
            justify-content: center
            align-items: center
            padding-top: $size-m
            padding-bottom: $size-m
        .addToQueueError
            text-align: center
            color: var(--failure)
        .searchContainer
            display: flex
            justify-content: center
            align-items: center
        .search
            padding-right: $size-m
            width: 100%
            flex: 1
        .searchSmall
            flex: 0.6
        .addToQueueButtonContainer
            border-radius: $size-l
            .addToQueueButton
                padding: $size-s
        .playlistInfoContainer
            display: flex
            gap: $size-s
            padding-bottom: $size-m
            .playlistInfoItem
                background: rgba(0, 0, 0, 0.2)
                width: fit-content
                padding: $size-s
                border-radius: $size-s
                display: flex
                align-items: center
                gap: $size-s
                .playlistInfoText
                    @extend .h3
                    font-size: 12px !important
                &.owner
                    background: rgba(255, 255, 255, 0.2)
                &.model
                    background: rgba(0, 0, 0, 0.4)
        .queueContainer
            margin-top: $size-l
            padding-bottom: $size-s2
        .divider
            margin-top: $size-l
        .inputerContainerSmall
            margin-top: $size-m
        .inputerContainer
            padding-bottom: $size-l
            .inputHeader
                @extend .h2
                padding-bottom: $size-s
                .privacyContainer
                    display: flex
                    align-items: center
                    .privacyText
                        @extend .h3
                        padding-left: $size-s
        .controls
            display: flex
            flex-direction: column
            gap: $size-m
            margin-top: $size-m2
            .bar
                display: flex
                align-items: center
                justify-content: center
                gap: $size-m
                .progress
                    flex: 1
                .text
                    font-size: 10pt
                    color: var(--light)
            .buttons
                display: flex
                align-items: center
                justify-content: center
                gap: $size-m2
                // .small
                .large
                    border: solid 1px var(--white)
                    display: flex
                    align-items: center
                    justify-content: center
                    width: 60px
                    height: 60px
                    border-radius: 30px
            .disclaimer
                font-size: 10pt
                color: var(--light)
                text-align: center
                opacity: 0.8
        .variantsContainer
            margin-top: $size-l2
            .otherVariants
                @extend .h2
                margin-bottom: $size-xs
            .hint
                margin-bottom: $size-m
                opacity: 0.6
            .variants
                display: flex
                gap: $size-s2
                overflow: scroll
                .variant
                    width: 15%
        .playlistButtonContainer
            display: flex
            flex-direction: column
            gap: $size-m
            .updatePlaylistButtonContainer
                border-radius: $size-l
                width: 100%
                .updatePlaylistButton
                    @extend .h3

@media only screen and (max-width: $phone)
    .homePageOuter
        padding-left: $size-m
