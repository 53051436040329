.playlistViewOuter
    overflow: hidden !important
    .display
        position: absolute
        overflow: hidden !important
        width: 100%
        height: 100%
        top: 0
        left: 0
        right: 0
        bottom: 0
        display: flex
        flex-direction: column
        justify-content: flex-end
        .backgroundUnderlay
            position: absolute
            background: linear-gradient(180deg, #222222 4.04%, #34654E 47.44%, #1D1D1D 94.36%)
            background-repeat: repeat-x
            background-size: contain
            background-position: center
            filter: blur($size-m2)
            z-index: -1
            top: -$size-l2
            left: -$size-l2
            right: -$size-l2
            bottom: -$size-l2
            overflow: hidden
        .image
            width: 100vw
            height: 100vh
            object-position: center
        .text, .imageFitSelect, .viewPlaylistButton
            position: absolute
            z-index: 1
            transition: 0.5s ease
        .text
            bottom: 0
            left: 0
            right: 0
            padding-top: $size-xl
            padding-bottom: $size-xl
            padding-left: $size-l
            padding-right: $size-l
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            color: white
            text-shadow: 1px 1px 1px black
            background-image: linear-gradient(0deg, rgba(0,0,0,1), rgba(47,192,120,0.5), rgba(47,192,120,0.0))
            .title
                font-size: 32pt
            .author
                font-size: 18pt
        .imageFitSelect
            top: $size-m2
            right: $size-m2
        .viewPlaylistButton
            top: $size-m2
            left: $size-m2
        .noCurrent
            position: absolute
            top: 0
            left: 0
            right: 0
            bottom: 0
            color: white
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            .title
                @extend .h1
            .subtitle
                @extend .h3
                margin-top: $size-s
                margin-bottom: $size-l
            .bottomLink
                margin-top: $size-l
            .link
                color: var(--primary) !important
