@use "sass:color"

@import "./index"
@import "./_variables"

/**
 *  Bootstrap
 */

@import "../../node_modules/bootstrap/scss/functions"

/**
 *  React Phone number Input
 */
@import react-phone-number-input/style.css

/**
 *  Fonts
 */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;600;700;900&display=swap')

@font-face
    font-family: 'Montserrat'
    font-weight: 300
    src: url('./assets/fonts/Montserrat/static/Montserrat-Thin.ttf') format('truetype')

@font-face
    font-family: 'Montserrat'
    font-weight: 400
    src: url('./assets/fonts/Montserrat/static/Montserrat-Black.ttf') format('truetype')

@font-face
    font-family: 'Montserrat'
    font-weight: normal
    src: url('./assets/fonts/Montserrat/static/Montserrat-Medium.ttf') format('truetype')

@font-face
    font-family: 'Montserrat'
    font-weight: 600
    src: url('./assets/fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype')

@font-face
    font-family: 'Montserrat'
    font-weight: bold
    src: url('./assets/fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype')

@font-face
    font-family: 'Montserrat'
    font-weight: 900
    src: url('./assets/fonts/Montserrat/static/Montserrat-ExtraBold.ttf') format('truetype')

// Construct theme; Sass objects must be defined in a single line
$themes: ( "light-mode": ( "black": #000000, "dark": #222222, "medium": #ABA1B5, "semilight": #E2DFE5, "light": #F3F3F3, "white": #FFFFFF, "primary": #2FC078, "secondary": #8C45C3, "tertiary": #379FFF, "success": #009618, "failure": #D40000, "warning": #FFC700, "danger": #F69400, "clear": transparent ), "dark-mode": ( "black": #FFFFFF, "dark": #FAFAFA, "medium": #AAAAAA, "semilight": #424242, "light": #202020, "white": #181818, "primary": #2FC078, "secondary": #AC67E1, "tertiary": #41C6FF, "success": #00DB23, "failure": #FF1D1D, "warning": #FFE81D, "danger": #FFA800, "clear": transparent ) )
@each $theme-name, $colors in $themes
    body
        @if $theme-name == "light-mode"
            &::-webkit-scrollbar
                scrollbar-color: light
        @else
            &::-webkit-scrollbar
                scrollbar-color: dark
        @each $name, $color in $colors
            --#{$theme-name}-#{$name}: #{$color}
            --#{$theme-name}-#{$name}-light: #{color.scale($color, $lightness: 30%)}
            --#{$theme-name}-#{$name}-lighter: #{color.scale($color, $lightness: 60%)}
            --#{$theme-name}-#{$name}-lightest: #{color.scale($color, $lightness: 90%)}
            --#{$theme-name}-#{$name}-dark: #{color.scale($color, $lightness: -30%)}
            --#{$theme-name}-#{$name}-darker: #{color.scale($color, $lightness: -60%)}
            --#{$theme-name}-#{$name}-darkest: #{color.scale($color, $lightness: -90%)}
            &.#{$theme-name}
                --#{$name}: #{$color}
                --#{$name}-light: #{color.scale($color, $lightness: 25%)}
                --#{$name}-lighter: #{color.scale($color, $lightness: 50%)}
                --#{$name}-lightest: #{color.scale($color, $lightness: 75%)}
                --#{$name}-dark: #{color.scale($color, $lightness: -25%)}
                --#{$name}-darker: #{color.scale($color, $lightness: -50%)}
                --#{$name}-darkest: #{color.scale($color, $lightness: -75%)}
                /**
                 * Gradients
                 */
                --graydient: linear-gradient(140deg, var(--light) 22%, var(--white) 145%)
                --primary-gradient: linear-gradient(170deg, var(--primary) 0%, var(--secondary) 100%)

/**
 *  General
 */

html
    color-scheme: var(--color-scheme)

html, body
    width: 100%
    height: 100%
    overflow: hidden

body > div
    height: 100%
    overflow: scroll
    -webkit-overflow-scrolling: touch

.App
    text-align: center

body
    font-family: 'Lato', sans-serif
    outline: none
    background: var(--white)
    color: var(--black)
    margin: 0

main
    overflow: hidden

textarea:focus, input:focus, *:focus
    outline: none !important

.appOuter
    background: linear-gradient(180deg, #222222 4.04%, #34654E 47.44%, #1D1D1D 94.36%)

.clickable
    cursor: pointer

.wrapper
    width: 100%
    position: relative

.container
    padding: 0 !important
    overflow: auto

.clip
    overflow: hidden

.flex-1
    flex: 1

.no-clip
    overflow: visible !important

.whole-page
    min-height: 100vh

.animated
    -webkit-transition: all 0.05s ease
    -moz-transition: all 0.05s ease
    -o-transition: all 0.05s ease
    transition: all 0.05s ease

.animated-slow
    -webkit-transition: all 0.5s ease
    -moz-transition: all 0.5s ease
    -o-transition: all 0.5s ease
    transition: all 0.5s ease

.h7
    font-size: 0.8rem

.h8
    font-size: 0.6rem

.h9
    font-size: 0.4rem

.justify-content-stretch
    justify-content: stretch

.word-wrap
    word-wrap: break-word

::selection
    background: var(--primary-lightest)
::-moz-selection
    background: var(--primary-lightest)

/**
 *  Dimensions
 */

.w-20
    width: 20%

.w-65
    width: 65%

.w-80
    width: 80%

.w-95
    width: 95%

/**
 *  Opacity
 */

.o-0
    opacity: 0

.o-25
    opacity: 0.25

.o-50
    opacity: 0.5

.o-75
    opacity: 0.75

.o-100
    opacity: 1

/**
 *  Line Height
 */

.lh-1
    line-height: 1

.lh-125
    line-height: 1.25

.lh-15
    line-height: 1.5

.lh-175
    line-height: 1.75

.lh-12
    line-height: 1.75

/**
 *  Colors
 */

.color-black
    color: var(--black)

.color-dark
    color: var(--dark)

.color-medium
    color: var(--medium)

.color-light
    color: var(--light)

.color-semilight
    color: var(--semilight)

.color-white
    color: var(--white)

.color-primary
    color: var(--primary)

.color-secondary
    color: var(--secondary-dark)

.color-tertiary
    color: var(--tertiary)

.color-danger
    color: var(--danger)

.color-failure
    color: var(--failure)

.color-success
    color: var(--success)

/**
 *  Backgrounds
 */

.bg-black
    background-color: var(--black) !important

.bg-dark
    background-color: var(--dark) !important

.bg-medium
    background-color: var(--medium) !important

.bg-light
    background-color: var(--light) !important

.bg-semilight
    background-color: var(--semilight) !important

.bg-white
    background-color: var(--white) !important

.bg-primary
    background-color: var(--primary) !important

.bg-secondary
    background-color: var(--secondary-dark) !important

.bg-tertiary
    background-color: var(--tertiary) !important

/**
 *	Cursors
 */

.cursor-default
    cursor: default !important

.cursor-pointer
    cursor: pointer !important

/**
 *	Overrides
 */

.sb-avatar__image
    object-fit: cover !important

.popup-content
    width: auto
    display: flex
    justify-content: center

.emoji-picker-react
    z-index: 10000

::placeholder
    color: var(--medium) !important
    opacity: 1 !important

:-ms-input-placeholder
    color: var(--medium) !important

::-ms-input-placeholder
    color: var(--medium) !important

.firebase-emulator-warning
    opacity: 0
    pointer-events: none

.carousel
    height: 100%

.slider-wrapper
    height: 100%
    .slider
        height: 100%

.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected
    background-color: var(--primary) !important

@import "../../node_modules/bootstrap/scss/variables"
@import "../../node_modules/bootstrap/scss/maps"
@import "../../node_modules/bootstrap/scss/mixins"
@import "../../node_modules/bootstrap/scss/root"

@import "../../node_modules/bootstrap/scss/containers"
@import "../../node_modules/bootstrap/scss/grid"
@import "../../node_modules/bootstrap/scss/helpers"

/** Mantine */
.mantine-Input-input
    border-radius: 1000px

.mantine-RichTextEditor-root
    a
        color: var(--primary)
        text-decoration: none
