.modelPickerOuter
    color: white
    display: flex
    flex-direction: column
    gap: $size-s
    .modelCardOuter
        display: flex
        justify-content: stretch
        gap: $size-m
        padding: $size-m2
        border-radius: $size-s
        background: rgba(0, 0, 0, 0.2)
        border: solid 2px var(--clear)
        &.selected
            border: solid 2px white
        .leftCol
        .rightCol
            flex: 1
            display: flex
            flex-direction: column
            gap: $size-xs2
            .titleRow
                display: flex
                justify-content: space-between
                align-items: center
                .title
                    font-size: 16pt
                    font-weight: bold
                .note
                    font-size: 8pt
                    font-weight: bold
                    text-transform: uppercase
                    &.free
                        color: var(--primary)
            .inputs
                display: flex
                flex-direction: column
                gap: $size-xs2
                padding-top: $size-m
                .reassurance, .docs
                    font-size: 10pt
                    color: var(--primary)
                    margin-top: $size-s
                .docs
                    font-weight: bold
                    text-align: right
