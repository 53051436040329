.addPlaylistOuter
    background: linear-gradient(180deg, #222222 4.04%, #34654E 47.44%, #1D1D1D 94.36%)
    padding-top: $size-xl
    padding-left: $size-m
    padding-right: $size-m
    color: white !important
    .padDown
        margin-bottom: $size-s
        &.helpText
            margin-top: 0 !important
    .textInput
        border-radius: $size-l
    .playlistInner
        min-height: 100vh
        overflow: hidden
        .addPlaylistText
            @extend .h1
            display: flex
            align-items: flex-end
            padding-top: $size-xl
            padding-bottom: $size-l
        .inputerContainer
            padding-bottom: $size-m2
            transition: all 0.3s ease
        .inputHeader
            @extend .h2
            padding-bottom: $size-s
            .privacyContainer
                display: flex
                align-items: center
                .privacyText
                    @extend .h3
                    padding-left: $size-s
        .helpText
            font-size: 10pt
            transition: all 0.3s ease
            margin-top: $size-s2
            .fakeLink
                color: var(--primary)
                padding-left: $size-xs
                opacity: 0.6
            &.helpLink, .link
                color: var(--primary)
                font-weight: bold
            .code
                font-family: monospace
        .addPlaylistButtonOuter
            display: flex
            justify-content: center
            flex-direction: column
            padding-top: $size-l
            .signUpAndAddPlaylist
                border-radius: $size-l
                width: 100%
            .sinupError
                @extend .h3
                color: var(--failure)
                padding-top: $size-s
                padding-bottom: $size-s
                text-align: center
        .termsAndConditions
            @extend .h4
            font-weight: 400
            color: #ADADAD !important
            width: 100%
            text-align: center
            padding-top: $size-m
            .link
                font-weight: bold
                color: var(--primary)
