/**
 *  Padding
 */

$size-xxs: 2px
$size-xxs2: 3px
$size-xs: 4px
$size-xs2: 6px
$size-s: 8px
$size-s2: 12px
$size-m: 16px
$size-m2: 24px
$size-l: 32px
$size-l2: 48px
$size-xl: 64px
$size-xl2: 96px
$size-xxl: 128px
$size-xxl2: 192px

/**
 * Breakpoints
 */
$phone: 576px
$tablet: 768px
$desktop: 992px

body
    font-family: Lato, sans-serif

.h1
    color: #FFFFFF
    font-family: 'Montserrat'
    font-style: normal
    font-weight: 700
    font-size: 48px
    line-height: 121.81%

.h2
    color: #FFFFFF
    font-family: 'Montserrat'
    font-style: normal
    font-weight: 700
    font-size: 22px
    line-height: 121.81%

.h3
    color: #FFFFFF
    font-family: 'Montserrat'
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 121.81%

.h4
    color: #FFFFFF
    font-family: 'Montserrat'
    font-style: normal
    font-weight: 500
    font-size: 12px
