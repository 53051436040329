@import './ConfirmCodePopup'
@import './ConfirmDeletePopup'

.overlay
    overflow: hidden !important
.popup
    background: linear-gradient(180deg, #222222 30%, #34654E 150%)
    border-radius: $size-m
    max-height: 95vh
    .inner
        height: 100%
        overflow: auto
