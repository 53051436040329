.footerOuter
    display: flex
    justify-content: center
    align-content: center
    flex-direction: column
    padding-top: $size-l
    padding-bottom: $size-l
    width: 100%
    color: white
    .footerButtons
        display: flex
        justify-content: center
        align-content: center
        gap: $size-m
        padding-bottom: $size-m

    .footerText
        @extend .h3
        text-align: center
        margin-top: $size-s
    .logoutText
        @extend .h3
        padding-top: $size-s
        cursor: pointer
        font-weight: 700
        text-align: center
