.scrollOuter
    .scrollText
        @extend .h2
        padding-left: $size-m

    .scrollInner
        display: flex
        overflow: scroll
        -ms-overflow-style: none
        scrollbar-width: none
        &::-webkit-scrollbar
            width: 0 !important
            display: none
        .imageOuter
            padding: $size-m
            padding-left: 0
            &:first-child
                padding-left: $size-m
            .image
                width: 140px
                height: 140px
                &.addImage
                    background: var(--primary)
                    display: flex
                    align-items: center
                    justify-content: center
                    width: 140px
                    height: 140px
                    color: white
                    font-size: 60px
                    font-weight: 700
                    border-radius: $size-s
            .imageTitle
                @extend .h3
                padding-top: $size-s
                text-align: center
                &.bold
                    font-weight: bold
