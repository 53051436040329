.docsOuter
    overflow: hidden !important
    min-height: 100vh
    background: linear-gradient(180deg, #222222 4.04%, #34654E 47.44%, #1D1D1D 94.36%)
    .docsInner
        padding-top: $size-l !important
        padding-bottom: $size-l !important
    .centerContainer
        margin-top: $size-m
        margin-bottom: $size-l
        display: flex
        align-items: center
        justify-content: center
