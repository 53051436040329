.buttonComponentMain
	display: inline-flex !important
	align-items: center
	justify-content: center
	height: 40px
	font-size: 10pt
	border-radius: $size-s
	border-width: 2px
	border-style: solid
	text-overflow: ellipsis
	overflow-x: hidden
	white-space: nowrap
	overflow: hidden
	text-align: center

	.loadingText
		opacity: 0.6
		margin-right: $size-m

	&.withImage
		padding-left: $size-m
		padding-right: $size-m

	&.rounded
		border-radius: 1000px !important

	.icon
		margin-right: $size-xs2

	.iconEnd
		margin-left: $size-xs2

	.icon, .iconEnd
		margin-top: -$size-xxs

	.image
		margin-right: $size-s
