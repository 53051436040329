.notFoundOuter
    background: linear-gradient(180deg, #222222 4.04%, #34654E 47.44%, #1D1D1D 94.36%)
    padding-top: $size-xl
    padding-left: $size-m
    padding-right: $size-m
    .notFoundInner
        height: 100vh
        .notFoundText
            @extend .h1
            padding-top: $size-xxl
        .notFoundButton
            background: #222222 !important
            color: white
            margin-top: $size-l
