@use '../../index' as *

.graphicOuter
    position: relative
    display: inline-block
    display: flex
    align-items: center
    justify-content: center
    .graphic
        height: 100%
        width: 100%
        overflow: hidden !important
        border-radius: $size-s
        background: black
    .loading
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        z-index: 1

@keyframes gradient
    0%
        background-position: -100% 0
    100%
        background-position: 100% 0
