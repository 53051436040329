.homePageOuter
    background: linear-gradient(180deg, #222222 4.04%, #34654E 47.44%, #1D1D1D 94.36%)
    padding-left: 0 !important
    min-height: 100vh
    .loadingContainer
        height: 90vh
        display: flex
        justify-content: center
        align-items: center
    .homePageInner
        display: flex
        flex-direction: column
        justify-content: space-between
        min-height: 100vh
    .welcomeText
        @extend .h1
        padding-left: $size-m
        display: flex
        align-items: flex-end
        padding-top: $size-xl
        padding-bottom: $size-l
    .deviceCarousel
        padding-top: $size-s

    .searchContainer
        display: flex
        justify-content: center
        align-items: center
    .search
        padding: $size-m

@media only screen and (max-width: $phone)
    .homePageOuter
