.interactableComponentMain
    cursor: pointer
    transition: 0.1s ease
    &.disabled
        cursor: default !important
a.interactableComponentMain
    color: inherit
    text-decoration: none
    display: block
    &:hover
        color: inherit
        text-decoration: none
        cursor: pointer
