@use '../../index' as *

.placeholder
    position: absolute
    &.static
        position: static
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
    background-repeat: no-repeat
    background: linear-gradient(to right, var(--light) 0%, var(--semilight) 50%, var(--light) 100%)
    display: inline-block
    background-size: 200% 200%
    animation: gradient 1.5s linear infinite forwards
    border-radius: $size-s

@keyframes gradient
    0%
        background-position: -100% 0
    100%
        background-position: 100% 0
